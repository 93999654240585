// OVERRIDE BOOTSTRAP DEFAULTS TO MATCH ANGULAR MATERIAL DEFAULTS
$font-family-sans-serif: Roboto, sans-serif;
$link-color: #673ab7; // Fix link color to default Angular Material
$label-margin-bottom: 0; // Fix Label Margin Bottom for default Angular Material

$grid-breakpoints: (
  xs: 0,
  // handset portrait (small, medium, large) | handset landscape (small)
  sm: 600px,
  // handset landscape (medium, large) | tablet portrait (small, large)
  md: 960px,
  // tablet landscape (small, large)
  lg: 1280px,
  // laptops and desktops
  xl: 1600px // large desktops,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);

$grid-xs: 0;
$grid-sm: 600px;
$grid-md: 960px;
$grid-lg: 1280px;
$grid-xl: 1600px;

$container-max-widths: (
  sm: 600px,
  md: 960px,
  lg: 1280px,
  xl: 1600px,
);

// Default variable overrides
$primary: #e6762c;
$secondary: #30728d;
$warning: #e24015;
$light: #c7c7c7;
$dark: #232a30;

// Inputs
$input-font-family: Roboto, sans-serif;
$input-font-size: 16px;
$input-font-size-sm: 16px;
$input-line-height: 1.25;
$input-focus-width: 0.1rem;
$input-color: #141719;

// Input Padding
$input-padding-y: 5px;
$input-padding-x: 10px;
$input-padding-y-sm: 5px;
$input-padding-x-sm: 10px;

// Input Border
$input-border-radius: 0;
$input-border-radius-sm: 0;
$input-border-color: #414b55;
$input-border-width: 1px;
$input-height-border: 1px;

// Forms
$form-label-margin-bottom: -10px;
$form-label-font-size: 16px;
$form-label-font-style: normal;
$form-label-font-weight: normal;
$form-label-color: #141719;

// Form Check/Radio Inputs
$form-check-input-border: 1px solid #232a30;
$form-check-input-border-radius: 0.1875em;
$form-check-input-focus-border: #2e7d9c;
$form-check-input-checked-bg-color: #2e7d9c;
$form-check-input-focus-box-shadow: 0 0 0 0.1rem rgba(46, 125, 156, 0.25);

// Form Select Inputs
$form-select-bg-size: 25px 20px; // In pixels because image dimensions
$form-select-indicator-color: #2e7d9c;
$form-select-indicator: url('/assets/angle-down-solid.svg');

// Form Validation/Feedback
$form-feedback-margin-top: 5px;
$form-feedback-font-size: 15px;
$form-feedback-font-style: italic;
$form-feedback-invalid-color: #ce301a;

// Modals
$modal-title-line-height: 26px;
$modal-content-border-radius: 15px;
$modal-transition: transform 0.1s ease-out;
$modal-header-border-width: 0;

// Modal Backdrop
$modal-backdrop-bg: #141719;
$modal-backdrop-opacity: 0.7;

// Modal sizes
$modal-sm: 525px;

// Tables
$table-cell-padding-y: 5px;
$table-cell-padding-x: 20px;
$table-cell-padding-y-sm: 0.8125rem;
$table-cell-padding-x-sm: 0.875rem;

$table-cell-vertical-align: top;

$table-color: #414b55;
//$table-bg: #FFFFFFCC;
//$table-accent-bg: #FFFFFFCC;

$table-th-font-weight: 600;

$table-border-factor: 0.1;
$table-border-width: 1px;
$table-border-color: #c75e18;

$table-bg-scale: 0%;
