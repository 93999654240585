.r2s-progress-label {
  font: normal normal bold 15px/20px Montserrat, sans-serif;
  letter-spacing: 0px;
  color: #232a30;
  margin-right: 1rem;
  //display: flex;
}

.r2s-progress-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.progress {
  flex-grow: 1;
}
