html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
}

// body {
//   margin: 0;
//   //font-family: Montserrat, sans-serif;
// }

.main {
  -webkit-box-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  background-color: #fffbfa;
}

.main-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}

a {
  &.r2s-text-link {
    font: normal normal normal 16px/22px Roboto;
    color: #30728d;
    letter-spacing: 0px;
    text-decoration: underline;
  }
}

.text-right {
  text-align: right;
}

::ng-deep .r2s-nav {
  border-bottom: 2px solid #ff8a3c4d;
}

::ng-deep .r2s-al-brdr {
  border-bottom: 3px solid #ff8a3c;
}

.gray-cell {
  background-color: #d3d3d3;
}

.orange-cell {
  background-color: #ff8a3c4d;
}

.emp-spec-name {
  line-height: 20px;
  letter-spacing: 0px;
  font-size: 1rem;
  font-style: normal;
  font-weight: normal;
  color: #141719;
  margin-bottom: 0px;
  margin-top: 5px;
}
