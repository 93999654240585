@import '../bootstrap-overrides/variables-default';

body {
  overflow-x: hidden;
}

.grid-container {

  @media (max-width: $grid-sm) {
    margin: 0;
  }
}
