#overlay {
  background: #ffffff;
  color: #666666;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5000;
  top: 0;
  left: 0;
  float: left;
  text-align: center;
  padding-top: 20%;
  opacity: 1;

  &.fade-out {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.3s, opacity 0.3s linear;
  }

  &.fade-in {
    visibility: visible;
    transition: opacity 0.5s linear;
    opacity: 0.8;
  }

  &.fade-in-initial {
    visibility: visible;
    transition: opacity 0.1s linear;
    opacity: 1;
  }
}

.spinner {
  margin: 0 auto;
  height: 100px;
  width: 100px;
  -moz-animation: rotate 0.8s infinite linear;
  -webkit-animation: rotate 0.8s infinite linear;
  animation: rotate 0.8s infinite linear;
  border: 15px solid $primary;
  border-right-color: transparent;
  border-radius: 50%;
}

#overlay-text {
  font-size: 18px;
  font-weight: bold;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
