// TODO simplify this so we don't repeat ourselves
label {
  &.form-label {
    line-height: 20px;
    letter-spacing: 0px;

    &.r2s-required:after {
      content: ' \002A';
      color: #2e7d9c;
      display: inline;
    }
  }

  &.form-check-label {
    line-height: 20px;
    letter-spacing: 0px;

    &.r2s-required:after {
      content: ' \002A';
      color: #2e7d9c;
      display: inline;
    }
  }
}

textarea {
  resize: none;
}

input {
  &.form-control-sm {
    max-height: 36px;
    min-height: 20px;
  }
}

select {
  &.form-select-sm {
    max-height: 36px;
    min-height: 20px;
  }

  option {
    &.r2s-select-default {
      font: normal normal normal 16px/20px Roboto;
      letter-spacing: 0px;
      // TODO: See if we can make 'select one' gray when nothing is selected...
      //color: #787677;
      //color: red;
    }
  }
}

.r2s-form-header-1 {
  font: normal normal bold 36px/40px Montserrat;
  letter-spacing: 0px;
  color: #000000;
}

.r2s-sub-lbl {
  font: normal normal 500 18px/20px Roboto;
  letter-spacing: 0px;
  color: #141719;

  &.r2s-required:after {
    content: ' \002A';
    color: #2e7d9c;
    display: inline;
  }
}

.dp-hidden {
  width: 0;
  margin: 0;
  border: none;
  padding: 0;
}
.custom-day {
  text-align: center;
  padding: 0.185rem 0.25rem;
  display: inline-block;
  height: 2rem;
  width: 2rem;
}
.custom-day.focused {
  background-color: #e6e6e6;
}
.custom-day.range,
.custom-day:hover {
  background-color: rgb(2, 117, 216);
  color: white;
}
.custom-day.faded {
  background-color: rgba(2, 117, 216, 0.5);
}

.r2s-datepicker-input-btn {
  background-color: transparent;
  border: 1px solid #414b55;
  color: #2e7d9c;
  //padding-top: 3px;
}

.stacked-dp {
  .ngb-dp-header {
    background-color: rgba(255, 138, 60, 0.3019607843);
  }

  .ngb-dp-month-name {
    background-color: rgba(255, 138, 60, 0.3019607843);
  }

  &.task-stack {
    .ngb-dp-months {
      flex-direction: column;
    }
  }
}

.stacked-dp-task {
  .ngb-dp-header {
    background-color: rgba(255, 138, 60, 0.3019607843);
  }

  .ngb-dp-month-name {
    background-color: rgba(255, 138, 60, 0.3019607843);
  }

  // .ngb-dp-months {
  //   flex-direction: column;
  // }
}

.ngb-dp-weekday {
  color: black !important;
}

.custom-select {
  &.custom-select-sm {
    &.grid-dropdown-filter {
      height: 25px;
      font-size: 13px;
      background-position: 90% 50%;
      border-radius: 7px;
      //width: 100%;
    }
  }
}

.form-control:read-only {
  background-color: #e9ecef;
  opacity: 1;
  cursor: default;
}

.form-select {
  &.read-only {
    background-color: #e9ecef;
    opacity: 1;
    cursor: default;
  }
}
