//* Custom theming for specific Angular Material Components to reduce bundle size
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use '../../../node_modules/@ag-grid-community/styles' as ag;
@import '../../../libs/styles/bootstrap-overrides/_variables-default.scss';
@include ag.grid-styles(
  (
    theme: alpine,
    header-background-color: #232a30,

    header-foreground-color: white,
    secondary-foreground-color: white,
    font-family: 'Roboto' sans-serif,
  )
);
// .dls-grid-override {
//   @include ag.grid-styles(
//     (
//       theme: alpine,
//       header-background-color: #ffffff,
//       font-family: 'Roboto' sans-serif,
//       // row-border-style: solid,
//       // row-border-width: 1px,
//       // row-border-color: #dde2eb
//       borders: solid 1px,
//       border-color: #dde2eb,
//       borders-secondary: solid 1px,
//       secondary-border-color: #dde2eb,
//     )
//   );
//   .ag-theme-alpine {
//     // .ag-cell-wrap-text {
//     //   padding-top: 2px;
//     //   padding-bottom: 2px;
//     //   line-height: normal;
//     // }
//   }
// }

.ag-theme-alpine {
  input[class^='ag-'][type='text'] {
    border-radius: 7px;
    color: black;
  }

  input[class^='ag-'][type='date'] {
    border-radius: 7px;
    color: black;
  }

  .ag-header-row-floating-filter {
    background-color: gray;
  }

  .ag-cell-wrap-text {
    padding-top: 10px;
    padding-bottom: 10px;
    line-height: normal;
  }

  .ag-root-wrapper {
    border: 0px !important;
    border-color: var(--ag-border-color, white) !important;
  }

  .ag-filter-icon {
    display: none;
  }

  .ag-header-group-cell-label {
    justify-content: center;
    text-align: center;
  }

  .ag-header-cell-label {
    //justify-content: center;
  }

  .ag-icon-filter {
    //display: none;
  }

  .ag-header-cell-label {
    .ag-sort-indicator-container {
      .ag-sort-indicator-icon {
        &.ag-sort-order {
          //display: none;
        }
      }
    }
  }

  .ag-header-icon {
    display: none;
  }

  .ag-floating-filter-full-body {
    justify-content: center;
  }

  .ag-header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .ag-horizontal-right-spacer {
    &:last-of-type {
      visibility: hidden;
    }
  }

  .ag-horizontal-left-spacer {
    &:first-of-type {
      visibility: hidden;
    }
  }
}

.column-group-empty {
  //visibility: hidden;
  //border: 2px solid red;
  //color: black;
  background-color: #c1c1c1 !important;
  border-top: none !important;
}

.column-group-unit {
  //border: 2px solid red;
  color: black;
  background-color: #fcd5b4 !important;
}
.column-group-gross {
  //border: 2px solid red;
  color: black;
  background-color: #c1c1c1 !important;
}
.column-group-last-year {
  //border: 2px solid red;
  color: black;
  background-color: #fcd5b4 !important;
}
.column-group-last-month {
  //border: 2px solid red;
  color: black;
  background-color: #c1c1c1 !important;
}

.column-group-mtd {
  //border: 2px solid red;
  color: black;
  background-color: #fcd5b4 !important;
}

.column-group-pvr {
  //border: 2px solid red;
  color: black;
  background-color: #c1c1c1 !important;
}

.column-group-roi {
  //border: 2px solid red;
  color: black;
  background-color: #fcd5b4 !important;
}

.column-group-trade {
  //border: 2px solid red;
  color: black;
  background-color: #c1c1c1 !important;
}

.column-group-imvp {
  //border: 2px solid red;
  color: black;
  background-color: #fcd5b4 !important;
}

.column-group-tracking {
  //border: 2px solid red;
  color: black;
  background-color: #c1c1c1 !important;
}

@include mat.core();

@import '../../../libs/styles/material/material_variables';

// Import and @include the theme mixins for each component that you are using.
//* Include mat.core-theme first by default
@include mat.core-theme($rts-client-theme);
//@include mat.autocomplete-theme($rts-client-theme);
@include mat.badge-theme($rts-client-theme);
//@include mat.bottom-sheet-theme($rts-client-theme);
@include mat.button-theme($rts-client-theme);
@include mat.button-toggle-theme($rts-client-theme);
@include mat.card-theme($rts-client-theme);
//@include mat.checkbox-theme($rts-client-theme);
//@include mat.chips-theme($rts-client-theme);
//@include mat.datepicker-theme($rts-client-theme);
//@include mat.dialog-theme($rts-client-theme);
//@include mat.divider-theme($rts-client-theme);
@include mat.expansion-theme($rts-client-theme);
//@include mat.form-field-theme($rts-client-theme);
@include mat.grid-list-theme($rts-client-theme);
@include mat.icon-theme($rts-client-theme);
//@include mat.input-theme($rts-client-theme);
@include mat.list-theme($rts-client-theme);
@include mat.menu-theme($rts-client-theme);
//@include mat.paginator-theme($rts-client-theme);
//@include mat.progress-bar-theme($rts-client-theme);
//@include mat.progress-spinner-theme($rts-client-theme);
//@include mat.radio-theme($rts-client-theme);
//@include mat.select-theme($rts-client-theme);
@include mat.sidenav-theme($rts-client-theme);
//@include mat.slide-toggle-theme($rts-client-theme);
//@include mat.slider-theme($rts-client-theme);
@include mat.snack-bar-theme($rts-client-theme);
//@include mat.sort-theme($rts-client-theme);
//@include mat.stepper-theme($rts-client-theme);
//@include mat.table-theme($rts-client-theme);
@include mat.tabs-theme($rts-client-theme);
@include mat.toolbar-theme($rts-client-theme);
//@include mat.tooltip-theme($rts-client-theme);
//@include mat.tree-theme($rts-client-theme);

//* Include parts of Bootstrap
// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import '../../../node_modules/bootstrap/scss/functions';
// 2. Include any default variable overrides here
@import '../../../libs/styles/bootstrap-overrides/variables-default';
// 3. Include remainder of required Bootstrap stylesheets
@import '../../../node_modules/bootstrap/scss/variables';
// 4. Include any default map overrides here

// Create your own map - this adds a custom color we can use in the html template: class="secondary-dark"
$custom-colors: (
  'secondary-dark': #232a30,
);

// Merge the maps - allows us to use the existing colors we have already defined in our custom variables file _variables-default.scss
$theme-colors: map-merge($theme-colors, $custom-colors);
// 5. Include remainder of required parts
@import '../../../node_modules/bootstrap/scss/maps';
@import '../../../node_modules/bootstrap/scss/mixins';
@import '../../../node_modules/bootstrap/scss/utilities';
@import '../../../node_modules/bootstrap/scss/root';

// 6. Optionally include any other parts as needed
@import '../../../node_modules/bootstrap/scss/reboot';
@import '../../../node_modules/bootstrap/scss/type';
@import '../../../node_modules/bootstrap/scss/images';
@import '../../../node_modules/bootstrap/scss/containers';
@import '../../../node_modules/bootstrap/scss/grid';
@import '../../../node_modules/bootstrap/scss/tables';
@import '../../../node_modules/bootstrap/scss/forms';
@import '../../../node_modules/bootstrap/scss/buttons';
@import '../../../node_modules/bootstrap/scss/transitions';
@import '../../../node_modules/bootstrap/scss/dropdown';
@import '../../../node_modules/bootstrap/scss/button-group';
//@import '../../../node_modules/bootstrap/scss/nav';
@import '../../../node_modules/bootstrap/scss/navbar';
//@import "../../../node_modules/bootstrap/scss/card";
@import '../../../node_modules/bootstrap/scss/accordion';
//@import "../../../node_modules/bootstrap/scss/breadcrumb";
@import '../../../node_modules/bootstrap/scss/pagination';
//@import "../../../node_modules/bootstrap/scss/badge";
@import '../../../node_modules/bootstrap/scss/alert';
@import '../../../node_modules/bootstrap/scss/progress';
@import '../../../node_modules/bootstrap/scss/list-group';
@import '../../../node_modules/bootstrap/scss/close';
@import '../../../node_modules/bootstrap/scss/toasts';
@import '../../../node_modules/bootstrap/scss/modal';
@import '../../../node_modules/bootstrap/scss/tooltip';
@import '../../../node_modules/bootstrap/scss/popover';
//@import '../../../node_modules/bootstrap/scss/carousel';
//@import '../../../node_modules/bootstrap/scss/spinners';
//@import '../../../node_modules/bootstrap/scss/offcanvas';
@import '../../../node_modules/bootstrap/scss/placeholders';

// Include Helper Classes/Functions
@import '../../../node_modules/bootstrap/scss/helpers';

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import '../../../node_modules/bootstrap/scss/utilities/api';
// Style overrides to bring bootstrap into alignment with overall style and theme
@import '../../../libs/styles/bootstrap-overrides/reset';

// Custom Style Imports
@import '../../../libs/styles/modules/overlay';
@import '../../../libs/styles/modules/typography';
@import '../../../libs/styles/modules/layout';
//@import '../../../libs/styles/modules/grid-list-cards';
@import '../../../libs/styles/modules/progress-bar';
@import '../../../libs/styles/modules/forms';
@import '../../../libs/styles/modules/modal';
@import '../../../libs/styles/modules/tables';
@import '../../../libs/styles/modules/pagination';
@import '../../../libs/styles/modules/toast';
