.page-item {
  margin-left: 5px;
  margin-right: 5px;
}

.page-link {
  // padding-top: 6px;
  // padding-bottom: 6px;
  border-radius: 8px;
  font: normal normal bold 16px/23px Montserrat;
  letter-spacing: 0.32px;
  color: #2e7d9c;
  border: 3px solid #2e7d9c;

  &.active-page {
    letter-spacing: 0.32px;
    color: #fffbfa;
    background-color: #1e4668 !important;
    border: 3px solid #1e4668;
  }

  &.dots {
    cursor: default !important;
    background-color: #e9ecef;
  }
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #267a88;
  border-color: #267a88;
}

.custom-select-grid {
  width: 4.3125rem;
  border: 1px solid #aeaeae;
  border-radius: 7px !important;
}

// .pagination-btn {
//   border-radius: 8px;
//   background-color: #2E7D9C;
// }
