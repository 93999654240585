// RESET BOOTSTRAP a TAG BEHAVIOR TO MATCH ANGULAR MATERIAL

a {
  &.mat-button,
  &.mat-raised-button,
  &.mat-fab,
  &.mat-mini-fab,
  &.mat-list-item {
    &:hover {
      color: currentColor;
    }
  }
}
