.r2s-goals-tbl-hdr {
  background-color: #232a30;
}

.table-dark {
  color: #ffffff;
  font: normal normal bold 16px/20px Roboto;
}

.r2s-table-detail {
  font: normal normal normal 16px/20px Roboto;
  letter-spacing: 0px;
  color: #232a30;
}
