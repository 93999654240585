.modal-body {
  font: normal normal normal 16px/22px Roboto;
  letter-spacing: 0px;
  padding-top: 0px;
}

.modal-title {
  font: normal normal bold 22px/26px Montserrat;
  letter-spacing: 0px;
}
